<template>
    <ul class="icon_list">
        <li tabindex="1" class="icon" v-if="appConfig.providerDetails.social.facebook"
          @mouseover="actSoicalLogin('fb')">
          <a :href="isActivefacebookLink ? appConfig.providerDetails.social.facebook : ''" rel="kee_facebook" aria-label="facebook link" target="_blank">
            <!-- <a href="#" rel="kee" aria-label="facebook link" target="_blank"> -->
            <!-- <a href="#" > -->
            <!-- <img src="@/assets/icons/cspace-facebookpage.svg" alt="facebook_img" class="fitShare" /> -->
            <FaceBookLogo />
          </a>
        </li>
        <li class="icon" tabindex="1" v-if="appConfig.providerDetails.social.instagram"
          @mouseover="actSoicalLogin('insta')">
          <a :href="isActiveInstagramLink ? appConfig.providerDetails.social.instagram : ''" rel="kee_insta" aria-label="insta link" target="_blank">
            <!-- <a href="#" rel="kee" aria-label="instagram link" target="_blank"> -->
            <!-- <a href="#" > -->
            <!-- <img src="@/assets/icons/cspace-instapage.svg" class="fitShare" /> -->
            <InstaLogo />
          </a>
        </li>
        <li tabindex="1" class="icon" v-if="appConfig.providerDetails.social.twitter"
          @mouseover="actSoicalLogin('twitter')">
          <a :href="isActiveTwitterLink ? appConfig.providerDetails.social.twitter : ''" rel="kee_twitter" aria-label="twitter link" target="_blank">
            <!-- <a href="#" rel="kee" aria-label="twitter link" target="_blank"> -->
            <!-- <a href="#" > -->
            <!-- <img src="@/assets/icons/cspace-xpage.svg" class="fitShare" /> -->
            <TwitterLogo />
          </a>
        </li>
        <li tabindex="1" class="icon" v-if="appConfig.providerDetails.social.youtube"
          @mouseover="actSoicalLogin('youtube')">
          <a :href="isActiveTwitterLink ? appConfig.providerDetails.social.youtube : ''" rel="kee_youTube" aria-label="youTube link" target="_blank">
            <!-- <a href="#" rel="kee" aria-label="twitter link" target="_blank"> -->
            <YouTubeLogo />
          </a>
        </li>
        <li tabindex="1" class="icon" v-if="appConfig.providerDetails.social.linkedin"
          @mouseover="actSoicalLogin('linkedin')">
          <a :href="isActiveTwitterLink ? appConfig.providerDetails.social.linkedin : ''" rel="kee_linkedin" aria-label="linkedin link" target="_blank">
            <!-- <a href="#" rel="kee" aria-label="twitter link" target="_blank"> -->
            <LinkedInLogo />
          </a>
        </li>
    </ul>
</template>
<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";
export default {
    data() {
        return {
            isActivefacebookLink: false,
            isActiveInstagramLink: false,
            isActiveTwitterLink: false,
            isActiveYoutubeLink: false,            
            isActiveLinkedinLink: false,            
            localDisplayLang: null,
        };
    },
    computed: {
        ...mapGetters(["appConfig", "getRtl"]),
    },
    methods: {
        ...mapMutations(["setRtl"]),
        actSoicalLogin(social) {
        let colorCode = '';
        if (social == 'fb') {
            colorCode = '#1877F2'
        } else if (social == 'insta') {
            colorCode = '#D748C0'
        } else if (social == 'twitter') {
            colorCode = '#1E90FF'
        } else if (social == 'tube') {
            colorCode = '#ED3833'
        }
        document.documentElement.style.setProperty('--social-login-hover-color', colorCode);
        },
    },
    mounted() {
        if (localStorage.getItem("setDisplayLanguageCode")) {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
        }
        this.isActivefacebookLink = window.PROVIDER_SETUP_CONFIG.isFacebookEnabled;
        this.isActiveInstagramLink = window.PROVIDER_SETUP_CONFIG.isInstagramEnabled;
        this.isActiveTwitterLink = window.PROVIDER_SETUP_CONFIG.isTwitterEnabled;
        this.isActiveYoutubeLink = window.PROVIDER_SETUP_CONFIG.isYoutubeEnabled;
        this.isActiveAboutusLink = window.PROVIDER_SETUP_CONFIG.isAboutusEnabled;
        this.isActiveAboutusLink = window.PROVIDER_SETUP_CONFIG.isLinkedinEnabled;
    },
    mixins: [Utility],
    components: {
      FaceBookLogo: () => import( /* webpackChunkName: "likeBtn" */ "@/components/SvgImages/FaceBook.vue"),
      TwitterLogo: () => import( /* webpackChunkName: "likeBtn" */ "@/components/SvgImages/Twitter.vue"),
      InstaLogo: () => import( /* webpackChunkName: "likeBtn" */ "@/components/SvgImages/Insta.vue"),
      YouTubeLogo: () => import( /* webpackChunkName: "likeBtn" */ "@/components/SvgImages/YouTube.vue"),
      LinkedInLogo: () => import( /* webpackChunkName: "likeBtn" */ "@/components/SvgImages/LinkedIn.vue"),
    }

}
</script>
<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.icon_list {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    min-height: 2rem;
    .icon{
      display: flex;
      align-items: center;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        .fitShare {
            width: 80%;
            height: 80%;
          }
      }
    }
  }
</style>